<template>
   <div id="main-wrapper">
     <!-- <Container/> -->
     <div class="position-relative">
       <section class="blockElement">
         <div class="container privacyPolicy">
           <h1 class="mb-4">{{contentlist.cookies_policy_content1}}</h1>
           <p>{{contentlist.cookies_policy_content2}} <router-link :to="entityPath+'/security'" class="secondarycolor">{{contentlist.capital_wallet_content}}</router-link> {{contentlist.cookies_policy_content3}}</p>
           <p>{{contentlist.cookies_policy_content4}} <router-link :to="entityPath+'/privacy-policy'" class="secondarycolor">{{contentlist.cookies_policy_content4_link}}</router-link> {{contentlist.cookies_policy_content5}}</p>
           <h6 class="mb-4 pt-2">{{contentlist.cookies_policy_content6}}</h6>
           <p>{{contentlist.cookies_policy_content7}}</p>
           <p>{{contentlist.cookies_policy_content8}}</p>
           <p>{{contentlist.cookies_policy_content9}}</p>
           <h6 class="mb-4 pt-2">{{contentlist.cookies_policy_content10}}</h6>
           <p>{{contentlist.cookies_policy_content11}} <a href="https://www.allaboutcookies.org" class="secondarycolor">{{contentlist.cookies_policy_content11_link}}</a> </p>
           <h6 class="mb-4 pt-2">{{contentlist.cookies_policy_content12}}</h6>
           <p>{{contentlist.cookies_policy_content13}}</p>
           <h6 class="mb-4 pt-2">{{contentlist.cookies_policy_content14}}</h6>
           <p>{{contentlist.cookies_policy_content15}}</p>
           <p>{{contentlist.cookies_policy_content16}}</p>
           <h6 class="mb-4 pt-2">{{contentlist.cookies_policy_content17}}</h6>
           <p>
             <strong>{{contentlist.cookies_policy_content18}}</strong>
           </p>
           <p>{{contentlist.cookies_policy_content19}}</p>
           <p>
             <strong>{{contentlist.cookies_policy_content20}}</strong>
           </p>
           <p>{{contentlist.cookies_policy_content21}}</p>
           <p>{{contentlist.cookies_policy_content22}}</p>
           <h6 class="mb-4 pt-2">{{contentlist.cookies_policy_content23}}</h6>
           <p>{{contentlist.cookies_policy_content24}}</p>
           <p>{{contentlist.cookies_policy_content25}} <a href="https://www.allaboutcookies.org" class="secondarycolor">{{contentlist.cookies_policy_content25_link}}</a></p>
           <p>{{contentlist.cookies_policy_content26}} <router-link :to="entityPath+'/privacy-policy'" class="secondarycolor">{{contentlist.cookies_policy_content26_link}}</router-link> {{contentlist.cookies_policy_content27}} <router-link :to="entityPath+'/security'" class="secondarycolor">{{contentlist.cookies_policy_content27_link}}</router-link>{{contentlist.cookies_policy_content28}} <a href="mailto:support@capitalwallet.com" class="secondarycolor">{{contentlist.support_email_content}}</a>
           </p>
           <router-link :to="entityPath+'/'" class="secondarycolor d-flex mt-4">
             <img src="/assets/images/arrow-left.webp" alt="Capital Wallet" title="Capital Wallet" width="24" height="24" class="img-fluid" />
             <span class="primarycolor ps-2 semibold">{{contentlist.cookies_policy_content29}}</span>
           </router-link>
         </div>
       </section>
     </div>
     <!-- <Footer /> -->
     <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
       <div class="container">
         <div class="row justify-content-center">
           <div class="col-12 text-center primarybgSection">
             <div class="criptoIcon">
               <span class="left-tp one">
                 <img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid">
               </span>
               <span class="left-tp tow">
                 <img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid">
               </span>
               <span class="left-tp three">
                 <img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid">
               </span>
               <span class="left-tp four">
                 <img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid">
               </span>
             </div>
             <div class="content position-relative">
               <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
               <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
               <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
             </div>
           </div>
         </div>
       </div>
     </section>
   </div>
 </template>
 <script>
 import { commonAllmixins } from '@/plugins/commonAll'   //anamica
   export default ({
    mixins: [ commonAllmixins],   //anamica
     data() {
       return {
         tab: 1,
       };
     },
   });
 </script>